<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Base</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link">
                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">
                        <div class="flex w-full overflow-hidden">
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                            <SingleProjectDetails ref="projectDetails" :details="response.body" :hasTable="false" @setLoading="setLoading" @saveClicked="saveClicked" @successForm="successForm"/>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrCompanyDepartmentsPagination from "@/v3/components/pagination/HrCompanyDepartmentsPagination.vue";
import HrCompanyContractsPagination from "@/v3/components/pagination/HrCompanyContractsPagination.vue";
import HrCompanyProjectsPagination from "@/v3/components/pagination/HrCompanyProjectsPagination.vue";
import SingleClientDetails from "@/v3/pages/SingleClientDetails.vue";
import fileDownload from "js-file-download";
import SingleProjectDetails from "@/v3/pages/SingleProjectDetails.vue";

export default {
    components: {
        SingleProjectDetails,
        SingleClientDetails,
        HrCompanyProjectsPagination,
        HrCompanyContractsPagination,
        HrCompanyDepartmentsPagination,
        HrPeoplePagination,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            company: null,
            departments: null,
            contracts: null,
            projects:null,
            departmentsOpen: true,
            contractsOpen: false,
            projectsOpen: false
        }
    },
    props:{
        app:{
            type: String,
            default: 'base'
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','').replace('/base','').replace('/hq','')
        },


    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        toggleDepartmentsOpen(){
            this.departmentsOpen = !this.departmentsOpen;
        },
        toggleContractsOpen(){
            this.contractsOpen = !this.contractsOpen;
        },
        toggleProjectsOpen(){
            this.projectsOpen = !this.projectsOpen;
        },
        getDarkLogo()
        {
            console.log(this.app);
            if(this.app === 'hq'){
                return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_dark"
            }
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"

        },
        getLightLogo()
        {
            console.log(this.app);
            if(this.app === 'hq'){
                return process.env.VUE_APP_API_URL + "/api/json/lottie?type=hq_light"
            }
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        },
        setLoading(){
            this.$refs.projectDetails.editing = false;
            this.$refs.projectDetails.tabOpen = 'basics';
            this.details = null;
            this.loadingDetails = true;
        },

        saveClicked() {
            if(!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.$refs.projectDetails.updateAction.perform(this.$refs.projectDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.projectDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.$refs.sirenRoot.load();
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        successForm(res){
            this.$refs.sirenRoot.load();
        },
    }
}
</script>

